<template>
  <div style="height:calc(100vh);background-color:#fff">
    <div class="customer-cell-group">
      <div class="customer-cell">
        <span class="title">企业名称</span>
        <span class="value">{{ detail.company_name }}</span>
      </div>
      <div class="customer-cell">
        <span class="title">纳税人类型</span>
        <span class="value">{{ detail.company_attribute }}</span>
      </div>
      <div class="customer-cell">
        <span class="title">申报月份</span>
        <span class="value">{{ detail.inv_time }}</span>
      </div>
      <div class="customer-cell">
        <span class="title">税费总额</span>
        <span class="value">{{ detail.tax_total }}</span>
      </div>
      <div class="customer-cell">税费明细</div>
      <div style="padding: 12px; color: #969799; font-size: 14px; line-height: 2;text-align: left;">
        {{ detail.tax_detail }}
      </div>
    </div>
  </div>
</template>

<script lang="js">

export default {
    data() {
        return {
            detail:{},
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        async initData(){
            const res = await axios.get('https://wechat.spiderair.com/api/taxCount',
            {params: { company_id: this.$route.query.cid,zq: this.$route.query.zq}}
            )

            if (res.data.status_code === 200){
                this.detail = res.data.data
                console.log(this.detail,res);
            }
        }
    },

}
</script>

<style lang="less" scoped>
.customer-cell-group {
  .customer-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #f5f6f7;
    font-size: 16px;

    .title {
      font-size: 16px;
    }

    .value {
      color: #969799;
    }
  }
}
</style>
